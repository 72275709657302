import { Link } from "gatsby"
import * as React from "react"

interface Props {
  items: Array<any>
  icon: string
}

const Item = ({ node, icon, isFirst = false }: any) => {
  return (
    <article className={isFirst ? "item item has-border-top" : `item has-border-top`}>
      <Link to={node.path}>
        <img style={{maxWidth: 27}} src={`/img/icons/${icon}`} />
        <h2>{node.title}</h2>
        <div>{node.description}</div>
      </Link>
    </article>
  )
}

const Rows = ({ items, icon }: Props) => {
  return (
    <div className="rows-guides">
      {items?.map((node: any, index) => (
        <Item
          icon={icon}
          isFirst={index === 0}
          key={`item-${index}`}
          node={node}
        />
      ))}
    </div>
  )
}

export default Rows
