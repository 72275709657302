import * as React from "react"
import Rows from "../guides/Rows"
import { splitGuides } from "../utils/Guide"

interface Props {
  documentation?: Array<any>
  guidesText?: any
  guides?: Array<any>
}

const resolveVersion = (
  str: string,
  value: string,
  current: boolean
): string => {
  if (current) {
    return str?.replace("{version}", "current")
  }
  return str?.replace("{version}", value)
}

const LineDoc = ({ doc }: any) => {
  return (
    <tr>
      <td>
        {doc.version}
        {doc.current && <span className="ml-3 tag current">CURRENT</span>}
        {doc.status === "GENERAL_AVAILABILITY" && (
          <span className="ml-3 tag ga">GA</span>
        )}
        {doc.status === "SNAPSHOT" && (
          <span className="ml-3 tag snapshot">SNAPSHOT</span>
        )}
        {doc.status === "PRERELEASE" && (
          <span className="ml-3 tag pre">PRE</span>
        )}
      </td>
      <td>
        {doc.ref && (
          <a href={resolveVersion(doc.ref, doc.version, doc.current)}>
            Reference Doc.
          </a>
        )}
      </td>
      <td>
        {doc.api && (
          <a href={resolveVersion(doc.api, doc.version, doc.current)}>
            Api Doc.
          </a>
        )}
      </td>
    </tr>
  )
}

const Learn = ({ documentation, guidesText, guides }: Props) => {
  const all = splitGuides(guides)
  return (
    <div className="py-4">
      <h2 className="is-size-4 pb-4 mb-2 has-text-weight-bold">
        Documentation
      </h2>
      <p className="mb-4">
        Each <strong>Spring project</strong> has its own; it explains in great
        details how you can use <strong>project features</strong> and what you
        can achieve with them.
      </p>
      <table className="table is-fullwidth mb-6">
        <tbody>
          {documentation?.map((doc, index) => (
            <LineDoc key={`line${index}`} doc={doc} />
          ))}
        </tbody>
      </table>

      {all.gettingStarted?.length > 0 && (
        <div className="guide mt-6">
          <h2 className="is-size-4  py-4 has-text-weight-bold">
            Get Started Guides
          </h2>
          <div className="my-3">{guidesText.frontmatter.guides}</div>
          <Rows icon="guide.svg" items={all.gettingStarted} />
        </div>
      )}

      {all.topical?.length > 0 && (
        <div className="guide mt-6">
          <h2 className="is-size-4 has-text-weight-bold">Topical Guides</h2>
          <div className="my-3">{guidesText.frontmatter.topicalGuides}</div>
          <Rows icon="topical.svg" items={all.topical} />
        </div>
      )}

      {all.tutorials?.length > 0 && (
        <div className="guide mt-6">
          <h2 className="is-size-4 has-text-weight-bold">Tutorials</h2>
          <div className="my-3">{guidesText.frontmatter.tutorials}</div>
          <Rows icon="tutorial.svg" items={all.tutorials} />
        </div>
      )}
    </div>
  )
}

export default Learn
