import { graphql } from "gatsby"
import * as React from "react"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import Learn from "../components/projects/Learn"
import Samples from "../components/projects/Samples"
import Sidebar from "../components/projects/Sidebar"
import { ProjectInfo } from "../components/utils/Model"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub, faStackOverflow } from "@fortawesome/free-brands-svg-icons"
import Support from "../components/projects/Support"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { getDocumentation } from "../components/utils/Project"
import highlightCode from "../components/utils/HighlightCode"

// props
interface Props {
  data: {
    page: ProjectInfo
    projects: {
      nodes: Array<ProjectInfo>
    }
    guides: {
      nodes: Array<{
        description: string
        name: string
        path: string
        title: string
        type: string
        projects: Array<string>
      }>
    }
    guidesText: {
      frontmatter: {
        title: string
        description: string
        guides: string
        topicalGuides: string
        tutorials: string
      }
    }
  }
}

// markup
const ProjectTemplate = (props: Props) => {
  const hash = typeof window !== "undefined" ? location.hash : ""
  const [tab, setTab] = React.useState<string>("overview")
  const projects: ProjectInfo[] = props.data.projects.nodes
  const project = props.data.page
  const content = props.data.page.body?.childMarkdownRemark?.html
  const springBootConfig =
    props.data.page.springBootConfig?.childMarkdownRemark?.html
  const guides = props.data.guides.nodes.filter(
    guide => guide.projects?.indexOf(project.slug) > -1
  )
  const documentationMemo = React.useMemo(
    () => getDocumentation(project.documentation),
    [project.documentation]
  )
  const currentVersion = documentationMemo?.find(doc => doc.current)?.version
  const changeTag = (str: string) => {
    setTab(str)
  }
  React.useEffect(() => {
    if (hash) {
      changeTag(hash.replace("#", ""))
    }
  }, [hash, changeTag])
  React.useEffect(() => {
    highlightCode()
  })
  return (
    <Layout
      seo={{
        title: props.data.page.title,
        hasNoSpring: true,
      }}
      className="project"
    >
      <div className="container py-6">
        <div className="columns">
          <div className="project-sidebar column is-3">
            <Sidebar projects={projects} current={project} />
          </div>
          <div className="project-content column is-9 pl-6">
            <h1 className="project-title is-size-2 mb-5 pb-3 has-text-weight-bold">
              <span className="mr-4">{project.title}</span>
              {currentVersion && (
                <span className="tag is-primary">{currentVersion}</span>
              )}
              <span className="social-links">
                {project.github && (
                  <a href={project.github} title="Github">
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                )}
                {project.stackOverflow?.childMarkdownRemark
                  ?.rawMarkdownBody && (
                  <a
                    href={
                      project.stackOverflow.childMarkdownRemark.rawMarkdownBody
                    }
                    title="Stack Overflow"
                  >
                    <FontAwesomeIcon icon={faStackOverflow} />
                  </a>
                )}
              </span>
            </h1>

            <div className="tabs has-background-light my-5">
              <ul>
                <li className={`${tab === "overview" ? "is-active" : ""}`}>
                  <a href="#overview">Overview</a>
                </li>
                <li className={`${tab === "learn" ? "is-active" : ""}`}>
                  <a href="#learn">Learn</a>
                </li>
                {project?.support && project?.support?.length > 0 && (
                  <li className={`${tab === "support" ? "is-active" : ""}`}>
                    <a href="#support">Support</a>
                  </li>
                )}
                {project?.samples && project?.samples?.length > 0 && (
                  <li className={`${tab === "samples" ? "is-active" : ""}`}>
                    <a href="#samples">Samples</a>
                  </li>
                )}
              </ul>
            </div>

            {tab === "overview" && (
              <>
                <div className="pb-4 pt-2 ">
                  <div className="markdown content">
                    <HTMLContent content={content}></HTMLContent>
                    {springBootConfig && (
                      <div className="pt-4">
                        <h2 className="mb-1 is-size-4">Spring Boot Config</h2>
                        <HTMLContent content={springBootConfig}></HTMLContent>
                      </div>
                    )}
                  </div>
                </div>
                <div className="pt-5 pb-6">
                  <div className="has-background-light px-5 py-5">
                    <img
                      src={"/img/logos/spring-initializr.svg"}
                      alt="Spring Initializr"
                      width={50}
                      className="is-pulled-left ml-2 mt-1 mr-4"
                    />
                    <h2 className="is-size-4 has-text-weight-bold">
                      Quickstart Your Project
                    </h2>
                    <p className="links-animate">
                      Bootstrap your application with{" "}
                      <a href="https://start.spring.io/">Spring Initializr</a>.
                    </p>
                  </div>
                </div>
              </>
            )}

            {tab === "learn" && (
              <Learn
                documentation={documentationMemo}
                guidesText={props.data.guidesText}
                guides={guides}
              />
            )}
            {tab === "samples" &&
              project.samples &&
              project?.samples?.length > 0 && (
                <>
                  <Samples samples={project.samples} />
                </>
              )}
            {tab === "support" &&
              project.support &&
              project.support.length > 0 && (
                <>
                  <Support versions={project.support} />
                </>
              )}
          </div>
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default ProjectTemplate

// graphQL queries
export const pageQuery = graphql`
  query ProjectTemplate($id: String!) {
    page: contentfulProject(id: { eq: $id }) {
      title
      description
      slug
      id
      parent {
        id
      }
      github
      stackOverflow {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      samples {
        title
        description
        url
        order
      }
      documentation {
        version
        api
        ref
        status
        current
      }
      support {
        branch
        initialDate
        ossEnforcedEnd
        ossPolicyEnd
        commercialEnforcedEnd
        commercialPolicyEnd
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      springBootConfig {
        childMarkdownRemark {
          html
        }
      }
    }
    guidesText: markdownRemark(
      frontmatter: { templateKey: { eq: "guides-template" } }
    ) {
      html
      frontmatter {
        title
        description
        guides
        topicalGuides
        tutorials
      }
    }
    projects: allContentfulProject(sort: { order: ASC, fields: order }) {
      nodes {
        id
        title
        description
        slug
        parentProject {
          id
        }
        status
        order
      }
    }
    guides: allApiGuide {
      nodes {
        description
        name
        title
        type
        path
        projects
      }
    }
  }
`
