import { Link } from "gatsby"
import * as React from "react"

interface Props {
  samples?: Array<{
    title: string
    description: string
    url: string
    order: number
  }>
}

const Sample = ({ sample, isFirst = false }: any) => {
  return (
    <article className={isFirst ? "" : `has-border-top`}>
      <a href={sample.url}>
        <img style={{ maxWidth: 27 }} src={`/img/icons/tutorial.svg`} />
        <h2>{sample.title}</h2>
        <div>{sample.description}</div>
      </a>
    </article>
  )
}

const Samples = ({ samples }: Props) => {
  return (
    <div className="guide pb-4">
      <p className="mb-5 is-size-4 has-text-weight-bold pt-4 pb-2">
        A few examples to try out:
      </p>
      <div className="columns-list samples">
        {samples?.map((sample, index) => (
          <Sample
            key={`sample-${index}`}
            isFirst={index === 0}
            sample={sample}
          />
        ))}
      </div>
    </div>
  )
}

export default Samples
