import { DateTime } from "luxon"
import * as React from "react"
import { getVersions } from "../utils/Project"
import { Releases } from "./Releases"

interface Props {
  versions?: Array<{
    branch: string
    initialRelease: string
    ossEnforcedEnd: string
    ossPolicyEnd: string
    commercialEnforcedEnd: string
    commercialPolicyEnd: string
  }>
}

const isPast = (date: DateTime): boolean => date < DateTime.now()

const Item = ({ version }: any) => {
  return (
    <tr>
      <td>
        <span className={`status mr-3 ${version.status}`}></span>
        {version.branch}
      </td>
      <td className={`${isPast(version.initialRelease) ? "past" : ""}`}>
        {version.initialReleaseDate}
      </td>
      <td className={`${isPast(version.endOfSupport) ? "past" : ""}`}>
        {version.endOfSupportDate}
      </td>
      <td className={`${isPast(version.endCommercialSupport) ? "past" : ""}`}>
        {version.endCommercialSupportDate}
      </td>
    </tr>
  )
}

const Support = ({ versions }: Props) => {
  const versionsMemo = React.useMemo(() => getVersions(versions), [versions])

  if (versionsMemo.length === 0) {
    return <></>
  }
  return (
    <div className="project-support">
      <table className="table is-fullwidth my-6">
        <thead>
          <tr>
            <th>Branch</th>
            <th>Initial Release</th>
            <th>End of Support</th>
            <th>End Commercial Support *</th>
          </tr>
        </thead>
        <tbody>
          {versionsMemo.map((version: any, index: number) => (
            <Item version={version} key={`item-${index}`} />
          ))}
        </tbody>
      </table>

      <div className="calendar-releases">
        <div className="timeline">
          <Releases releases={versionsMemo} />

          <div className="release-legend has-bigest-border-light px-5 py-4 my-6">
            <div className="legend-block oss my-3">
              <h3 className="is-size-6 has-text-weight-bold">OSS support</h3>
              <p>
                Free security updates and bugfixes with support from the Spring
                community. See{" "}
                <a href="https://tanzu.vmware.com/support/oss">
                  VMware Tanzu OSS support policy
                </a>
                .
              </p>
            </div>

            <div className="legend-block commercial my-3">
              <h3 className="is-size-6 has-text-weight-bold">
                Commercial support
              </h3>
              <p>
                Business support from Spring experts during the OSS timeline,
                plus extended support after OSS End-Of-Life.
                <br />
                Publicly available releases for critical bugfixes and security
                issues when requested by customers.
              </p>
            </div>
            <div className="legend-block future my-3">
              <h3 className="is-size-6 has-text-weight-bold">Future release</h3>
              <p>
                Generation not yet released, timeline is subject to changes.
              </p>
            </div>
          </div>

          <div className="has-background-light has-text-centered p-6 mb-6 commercial-support">
            <h2 className="is-size-4 has-text-weight-bold mb-3">
              About commercial support (*)
            </h2>
            <div>
              This page shows the current state of project releases and does not
              define the commercial support policy. Please refer to the official
              support policy for more information.
            </div>
            <div className="mt-3">
              <a
                className="button is-spring"
                href="https://tanzu.vmware.com/spring-runtime"
              >
                Learn more about commercial support.
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Support
