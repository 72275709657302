import { Link } from "gatsby"
import * as React from "react"
import { ProjectInfo } from "../utils/Model"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons"

interface Props {
  projects: Array<ProjectInfo>
  current: ProjectInfo
}
interface ItemProps {
  title: string
  description?: string
  slug: string
  current: string
  parent: string
  children?: Array<ItemProps>
}

const hasChild = (children: any): boolean => children && children.length > 0
const hasActive = (current: string, slug: string, children?: Array<any>) => {
  if (current === slug) {
    return true
  }
  if (children) {
    return children.some(item => item.slug === current)
  }
  return false
}

const Item = ({ title, slug, current, children }: ItemProps) => (
  <li>
    <Link
      to={`/projects/${slug}`}
      className={current === slug ? "is-active" : ""}
    >
      {hasChild(children) && hasActive(current, slug, children) && (
        <FontAwesomeIcon style={{width: 14}} className="is-pulled-right" icon={faAngleDown} />
      )}
      {hasChild(children) && !hasActive(current, slug, children) && (
        <FontAwesomeIcon style={{width: 14}} className="is-pulled-right" icon={faAngleRight} />
      )}
      {title}
    </Link>
    {hasChild(children) && hasActive(current, slug, children) && (
      <ul>
        {children?.map((child: ItemProps, index: number) => (
          <Item
            title={child.title}
            slug={child.slug}
            current={current}
            parent={child.parent}
            children={child.children}
            key={index}
          />
        ))}
      </ul>
    )}
  </li>
)

const sort = (a: ProjectInfo, b: ProjectInfo) => {
  if (a.order === b.order) {
    return a.slug > b.slug ? 1 : -1
  }
  return a.order > b.order ? 1 : -1
}

const transformArrayToMap = (projects: Array<ProjectInfo>) => {
  const root = projects
    .filter((project: ProjectInfo) => {
      return project.status === "ACTIVE" || project.status === "COMMUNITY"
    })
    .filter((project: ProjectInfo) => !project.parentProject)
    .sort(sort)
  const children = projects
    .filter((project: ProjectInfo) => !!project.parentProject)
    .sort(sort)
  return root.map((item: ProjectInfo) => {
    const items = children
      .filter((child: ProjectInfo) => child?.parentProject?.id === item.id)
      .map((child: ProjectInfo) => ({ ...child }))

    return {
      ...item,
      children: items,
    }
  })
}

const Sidebar = ({ projects, current }: Props) => {
  const tree = React.useMemo(() => transformArrayToMap(projects), [projects])
  return (
    <aside className="menu">
      <ul className="menu-list">
        {tree.map((project: any, index: number) => (
          <Item
            title={project.title}
            slug={project.slug}
            current={current.slug}
            parent={project.parent}
            children={project.children}
            key={index}
          />
        ))}
      </ul>
    </aside>
  )
}

export default Sidebar
